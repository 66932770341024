export const DASHBOARD_API = 'dashboard' as const;
export const DASHBOARD_ENDPOINT_MAP = {
  outstandingRisk: {
    chart: 'outstanding-risk-chart',
    breakdown: 'outstanding-risk-breakdown',
  },
  removedRisk: {
    chart: 'removed-risk-chart',
    breakdown: 'removed-risk-breakdown',
  },
  criticalBugs: {
    chart: 'critical-bugs-chart',
    breakdown: 'critical-bugs-breakdown',
  },
  dealBreakers: {
    chart: 'deal-breakers-chart',
    breakdown: 'deal-breakers-breakdown',
  },
  slaBreach: {
    chart: 'sla-breach-chart',
    breakdown: 'sla-breach-breakdown',
  },
  accountTier: {
    chart: 'account-tier-chart',
    breakdown: 'account-tier-breakdown',
  },
  bugBurndown: 'bug-burndown',
  outstandingGain: {
    chart: 'outstanding-gain-chart',
    breakdown: 'outstanding-gain-breakdown',
  },
  wonGain: {
    chart: 'won-gain-chart',
    breakdown: 'won-gain-breakdown',
  },
  highPriorityFeatures: {
    chart: 'high-priority-features-chart',
  },
  featureByPriority: {
    chart: 'feature-by-priority',
  },
  customerCommitment: {
    chart: 'customer-commitment',
  },
  dealBreakerFeatures: {
    chart: 'deal-breaker-feature-chart',
  },
  deadlineBreach: {
    chart: 'deadline-breach-chart',
  },
  accountTierFeatures: {
    chart: 'account-tier-feature-chart',
  },
  custom: {
    chart: 'custom-chart',
  },
  myDashboards: 'my-dashboards',
} as const;
