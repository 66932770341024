import { DashboardRiskRes } from './api';
import {
  DashboardBugInfo,
  DashboardDonutChartCurrency,
  DashboardDonutChartCurrencyWrapper,
} from './ui';

export type DashboardBugRisk = Omit<DashboardRiskRes, 'itemCategoryList'> & {
  chart: DashboardDonutChartCurrencyWrapper;
  info: DashboardBugInfo;
};

export function mapDashboardRiskToDashboardDonut({
  itemCategoryList,
  ...res
}: DashboardRiskRes): DashboardBugRisk {
  const chartDataList = Object.values(itemCategoryList).reduce<
    DashboardDonutChartCurrency[]
  >(
    (list, value) => [
      ...list,
      {
        label: value.category.shortName,
        fullLabel: value.category.name,
        value: { 0: value.risk },
        count: { 0: value.count },
        category: value.category,
      },
    ],
    [],
  );
  return {
    ...res,
    info: {
      risk: res.totalRisk,
      count: res.itemCount,
      totalRelatedArr: res.totalArr,
      cost: res.cost,
      relatedAccounts: res.relatedCustomers,
    },
    chart: {
      data: chartDataList,
      totalRisk: [res.totalRisk],
      totalCount: [res.itemCount],
    },
  };
}
