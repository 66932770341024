import {
  DashboardPageFilter,
  DashboardPageFilterQueryParams,
  toDashboardPageFilterQueryParams,
} from '../ui';
import {
  getTimelineFilterDates,
  PwReplaceProperties,
  PwTimelineFilter,
  shouldTimelineFilterGetQuarterStartDate,
} from '@pwiz/infra/ts';
import { ISettings } from '@pwiz/entity/ts';
import { getCurrentQuarterStartDate } from '@pwiz/settings/ts';

export type DashboardDtoReq = DashboardPageFilterQueryParams;
export type DashboardReq = PwReplaceProperties<
  DashboardPageFilter,
  'timeline' | 'active',
  PwTimelineFilter
>;
export type DashboardActiveReq = PwReplaceProperties<
  DashboardPageFilter,
  'timeline',
  PwTimelineFilter
>;
export type DashboardActiveReqDto = DashboardPageFilterQueryParams;

export function toDashboardReq(filter: DashboardPageFilter): DashboardDtoReq {
  const { active, ...dashboardReq } = toDashboardPageFilterQueryParams(filter);
  return dashboardReq;
}

export function toDashboardActiveReq(filter: DashboardPageFilter) {
  return toDashboardPageFilterQueryParams(filter);
}

export function isDashboardDecisionReq(dashboardReq: DashboardReq) {
  return dashboardReq.divisionId !== null;
}

export async function getDashboardQueryDates(
  dashboardReq: DashboardReq,
  settingsCallback: () => Promise<ISettings>,
) {
  if (shouldTimelineFilterGetQuarterStartDate(dashboardReq)) {
    const settings = await settingsCallback();
    return getTimelineFilterDates(
      dashboardReq,
      getCurrentQuarterStartDate(settings),
    );
  }
  return getTimelineFilterDates(dashboardReq, null);
}
