import { DashboardMultiGroupRes } from '../../api';
import { DashboardCategory } from '../../category';

type ChartValueArr<T> = Record<number, T>;

export interface DashboardMultiGroupByCategory {
  category: DashboardCategory;
  count: ChartValueArr<number>;
  value: ChartValueArr<number>;
}

export interface DashboardMultiGroup {
  byCategory: DashboardMultiGroupByCategory[];
  total: {
    count: ChartValueArr<number>;
    value: ChartValueArr<number>;
  };
}

export function toDashboardMultiGroup({
  total,
  itemCategoryList,
}: DashboardMultiGroupRes): DashboardMultiGroup {
  const byCategory: DashboardMultiGroup['byCategory'] = [];
  for (let i = 0; i < itemCategoryList.length; i++) {
    byCategory.push({
      category: itemCategoryList[i].category,
      count: toChartValueArr(itemCategoryList[i].count),
      value: toChartValueArr(itemCategoryList[i].value),
    });
  }
  return {
    byCategory,
    total: {
      count: total.count,
      value: total.value,
    },
  };
}

function toChartValueArr<T>(arr: T[]): ChartValueArr<T> {
  const res: ChartValueArr<T> = {};
  for (let i = 0; i < arr.length; i++) {
    res[i] = arr[i];
  }
  return res;
}
